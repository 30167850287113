import React from 'react'

const CopyLink = ({ url, children }) => {
	// const textAreaRef = useRef(null)

	const copyToClipboard = e => {
		// textAreaRef.current.select()
		if (document) {
			const el = document.createElement('textarea')
			el.value = url
			el.setAttribute('readonly', '')
			el.style.position = 'absolute'
			el.style.left = '-9999px'
			document.body.appendChild(el)
			el.select()
			document.execCommand('copy')
			document.body.removeChild(el)
			// document.execCommand('copy')
		}
		e.target.focus()
	}

	return (
		<>
			<div className="cursor-pointer" onClick={copyToClipboard}>
				{children}
			</div>
			{/* <textarea className="winvisible" ref={textAreaRef} value={url} /> */}
		</>
	)
}

export default CopyLink
