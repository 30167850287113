import { graphql } from 'gatsby'
import React from 'react'
import BlogShare from '../components/blogShare'
import Layout from '../components/layout'
import Profile from '../components/profile'
import SEO from '../components/seo'

const BlogTemplate = ({ data, location }) => {
	const { markdownRemark } = data
	const { frontmatter, html, timeToRead } = markdownRemark
	const {
		file: { childImageSharp }
	} = data

	const node = {
		published_on: frontmatter.date
	}
	return (
		<Layout>
			<SEO
				title={frontmatter.title}
				url={location.href}
				node={node}
				article
				description={frontmatter.title}
				type="article"
				keywords={frontmatter.tags && frontmatter.tags.split(',')}
			/>
			<div className="blog-post flex-grow">
				<h1 className="text-3xl font-black font-mont leading-relaxed text-blue-300">
					{frontmatter.title}
				</h1>
				<span className="text-sm font-light tracking-wider">
					📆 {frontmatter.date} • ⏲️ {timeToRead} min read.
				</span>
				<div
					className="leading-relaxed font-nunito my-5 markdown flex-grow"
					dangerouslySetInnerHTML={{ __html: html }}
				/>
			</div>
			<BlogShare title={frontmatter.title} url={location.href} />
			<Profile image={childImageSharp} />
		</Layout>
	)
}

export const pageQuery = graphql`
	query($path: String!) {
		markdownRemark(frontmatter: { path: { eq: $path } }) {
			html
			timeToRead
			frontmatter {
				date(formatString: "MMMM DD, YYYY")
				path
				title
			}
		}
		file(relativePath: { eq: "me.png" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`

export default BlogTemplate
