import React from 'react'
import {
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TwitterIcon,
	TwitterShareButton
} from 'react-share'
import CopyIcon from '../images/copy.svg'
import CopyLink from './copyLink'

const BlogShare = ({ title, url }) => {
	return (
		<div className="text-center flex items-center justify-center">
			<span className="font-bold mr-5">Share on:</span>
			<TwitterShareButton
				className="mr-2"
				url={url}
				title={`${title} from @anshumaniac`}
			>
				<TwitterIcon className="rounded-full" size={20} />
			</TwitterShareButton>
			<LinkedinShareButton url={url} className="mr-2">
				<LinkedinIcon className="rounded-full" size={20} />
			</LinkedinShareButton>
			<FacebookShareButton url={url} className="mr-2">
				<FacebookIcon className="rounded-full" size={20} />
			</FacebookShareButton>
			<CopyLink url={url}>
				<img src={CopyIcon} alt="Copy Icon" />
			</CopyLink>
		</div>
	)
}

export default BlogShare
