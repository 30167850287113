import Img from 'gatsby-image'
import React from 'react'
import ExternLink from './externLink'
import Email from '../images/Social/email.svg'
import TW from '../images/Social/twitter.svg'

const ProfileCard = ({ image }) => {
	return (
		<section className="my-5 flex flex-col items-center max-w-50 border-t-2 py-3 sm:py-10 border-gray-700">
			<div className="flex">
				<Img
					alt="anshumanv in kyoto"
					className="rounded-full w-20 border-solid self-center border-purple-300 border-4"
					fluid={image.fluid}
				/>
				<div className="flex flex-col ml-5">
					<span className="text-teal-300 text-2xl font-bold">
						Anshuman Verma
					</span>
					<span>
						Full Stack Developer, Anime-maniac, ardent football guy and a
						terrible gamer.
					</span>
				</div>
			</div>
			<div className="flex mt-5 self-center">
				<ExternLink
					className="mr-5"
					href="https://twitter.com/anshumaniac"
					title="Anshuman's Twitter"
				>
					<img alt="anshumanv on twitter" src={TW} />
				</ExternLink>
				<ExternLink href="mailto:me@anshumanv.dev" title="Email Anshuman">
					<img alt="anshumanv on twitter" src={Email} />
				</ExternLink>
			</div>
		</section>
	)
}

export default ProfileCard
